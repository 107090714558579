export enum AppEvents {
	LOGIN_APP = "login",
	LOGOUT = "logout",
	CALL_LOGOUT = "call_logout",
	HOME_ENTER = "home:enter",
	ATUALIZAR_MENUS = "atualizarMenus",
	TIROU_FOTO_VALIDACAO_FACIAL = "validacaoFacial:tirouFoto",
	FOTO_REENVIO_DOCUMENTO = "reenviarDoc:tirouFoto",

	MODIFICOU_DEPENDENTES = "recadastramento:modificouDependentes" 
}
