import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import aes from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import jwt_decode from 'jwt-decode';
import { APP_CONFIG, AUTH_END_POINT, WEBSERVICE } from 'src/app/commom/constants';
import { AppEvents } from 'src/app/enums/app-events.enum';
import { StorageKeys } from 'src/app/enums/app-storage-keys';
import { MenuType } from 'src/app/enums/menu-type.enum';
import { TipoRecadastramento } from 'src/app/enums/tipo-recadastramento.enum';
import { TipoServidor } from 'src/app/enums/tipo-servidor.enum';
import { PKCEHelper } from 'src/app/helpers/pkce.helper';
import { UtilHelper } from 'src/app/helpers/utils.helper';
import { DevicePush } from 'src/app/models/device-push.model';
import { ErroApp } from 'src/app/models/erro.model';
import { MenuItem } from 'src/app/models/menu-item.model';
import { PerfilUsuario } from 'src/app/models/perfil-usuario.model';
import { Recadastramento } from 'src/app/models/recadastramento.model';
import { environment } from 'src/environments/environment';
import { EventService } from './event.service';
import { RecadastramentoService } from './recadastramento.service';
import { RemoteService } from './remote.service';

@Injectable({
	providedIn: 'root'
})
export class UsuarioService {

	private static readonly KEY: string = "UHJQcjN2MWRlbmNpQA==";

	usuario: any;
	perfilUsuario: PerfilUsuario;
	tipoRecadastramento: TipoRecadastramento;

	periodoContracheque: { maximo: string, minimo: string };
	anosRendimentos: Array<string>;

	dados: Recadastramento;

	constructor(
		private eventService: EventService,
		private iab: InAppBrowser,
		private platform: Platform,
		private recadastramentoService: RecadastramentoService,
		private remoteService: RemoteService
	) {
		this.remoteService.setUserService(this);
	}

	/**
	 * Retorna promisse com o usuário logado salvo no Preferences.
	 */
	async carregarUsuario(): Promise<any> {
		try {
			console.log("carregarUsuario");
			if (this.usuario == null) {
				const usuarioDecrypted: any = await this.decrypt("usuario", true);
				console.log("usuarioEncrypted");
				if (usuarioDecrypted) {
					this.usuario = usuarioDecrypted;
					const token: string = await this.decrypt("token");
					this.setAuthToken(token);

					const perfil: PerfilUsuario = await this.decrypt("perfil", true);
					if (perfil) {
						this.perfilUsuario = perfil;
					}
					/**
					 * Forma para habilitar durante desenv o perfil de atendente.
					 */
					if (UtilHelper.isDevMode()) {
						// this.perfilUsuario.atendeBalcao = "S";
					}
				}
			}
			return this.usuario;
		} catch (error) {
			console.error(error);
			return this.usuario;
		}
	}

	async salvarUsuario(usuario: any): Promise<void> {
		this.usuario = usuario;
		this.encrypt("usuario", this.usuario, true);
	}

	async removerUsuario(): Promise<void> {
		try {
			await Preferences.remove({ key: "usuario" });
			await Preferences.remove({ key: "token" });
			await Preferences.remove({ key: "refreshToken" });
			await Preferences.remove({ key: "perfil" });	
		} catch (error) {
			console.error(error);
		}
		this.usuario = null;
		this.perfilUsuario = null;
		this.tipoRecadastramento = null;
		this.remoteService.setAuthToken(null);
	}

	setAuthToken(token: string): void {
		this.remoteService.setAuthToken(token);
	}

	async obterMenus(callbackAbrirPagina: any, isMenuLateral: boolean): Promise<Array<MenuItem>> {
		let menusItem: Array<MenuItem> = [
			{ titulo: "Contracheque", subtitulo: "Demonstrativo de Pagamentos", page: "contracheque", icon: "assets/icon/home/contracheque.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO, TipoServidor.PENSIONISTA], atendente: false, isSubmenuServidor: true, type: MenuType.MAIN },
			{ titulo: "Comprovante de Rendimentos", subtitulo: "comprovante de rendimentos", page: "rendimentos", icon: "assets/icon/home/rendimentos.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO, TipoServidor.PENSIONISTA], atendente: false, isSubmenuServidor: true, type: MenuType.MAIN },
			{ titulo: "Recadastramento", subtitulo: "recadastramento obrigatório", page: "tabs-recadastramento", params: { segment: "recadastramento" }, icon: "assets/icon/home/recadastramento.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO, TipoServidor.PENSIONISTA], atendente: true, isSubmenuRecadastramento: true, type: MenuType.MAIN },
			{ titulo: "Dados Pessoais", subtitulo: "ou atualização cadastral", page: "dados-pessoais", icon: "assets/icon/home/recadastramento.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO, TipoServidor.PENSIONISTA], atendente: true, isSubmenuRecadastramento: true, type: MenuType.MAIN },
			{ titulo: "Dependentes", subtitulo: "cadastro de dependentes", page: "dependentes", icon: "assets/icon/home/dependentes.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO], atendente: true, isSubmenuRecadastramento: true, type: MenuType.MAIN },
			{ titulo: "Representante Legal", subtitulo: "cadastro de representantes", page: "representante-legal", icon: "assets/icon/home/representante.svg", acao: callbackAbrirPagina, perfisAcesso: [TipoServidor.ATIVO_ESTADO, TipoServidor.APOSENTADO, TipoServidor.PENSIONISTA], atendente: true, isSubmenuRecadastramento: true, type: MenuType.SECONDARY },
			{ titulo: "Educação Previdenciária", subtitulo: "", page: "educacao", icon: "assets/icon/home/educacao.svg", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true, isSubmenuRecadastramento: false, type: MenuType.SECONDARY }
		];

		if (!this.perfilUsuario) {
			await this.obterPerfil(this.usuario.upn, true);
			if (!this.perfilUsuario) {
				throw new ErroApp(APP_CONFIG.ERROS.TRATADO_APP, "Não foi possível identificar o perfil do usuário.");
			}
		}
		let menuToRemoveIndex: number = -1;
		if (this.perfilUsuario.atendeBalcao == "N") {
			if (!this.dados || this.usuario.upn != this.dados.cpf) {
				await this.obterDados();
			}
			if (this.dados) {
				this.tipoRecadastramento = this.dados.codTipoRecadastramento;
			}
			if (!isMenuLateral) {
				if (this.tipoRecadastramento == TipoRecadastramento.RECADASTRAMENTO_OBRIGATORIO) {
					menuToRemoveIndex = menusItem.findIndex((m) => m.page == "dados-pessoais");
				} else if (this.tipoRecadastramento == TipoRecadastramento.MANUTENCAO_CONTINUA) {
					menuToRemoveIndex = menusItem.findIndex((m) => m.page == "tabs-recadastramento");
				}
			}
		} else {
			menuToRemoveIndex = menusItem.findIndex((m) => m.page == "dados-pessoais");
		}
		if (menuToRemoveIndex > -1) {
			menusItem.splice(menuToRemoveIndex, 1);
		}

		// Filtra para mostrar apenas os menus que todos podem acessar, ou somente o perfil que tem acesso.
		menusItem = menusItem.filter((menu) => menu.perfisAcesso.length == 0 || menu.perfisAcesso.find((p) => p == this.perfilUsuario.tipoServidor) != null || (menu.atendente && this.perfilUsuario.atendeBalcao == "S"));

		if (this.usuario.upn == environment.globalParams.cpfTesteApple) {
			menusItem.push({ titulo: "Contracheque", subtitulo: "Demonstrativo de Pagamentos", page: "contracheque", icon: "assets/icon/home/contracheque.svg", acao: callbackAbrirPagina, perfisAcesso: [], atendente: false, isSubmenuServidor: true, type: MenuType.MAIN });
			menusItem.push({ titulo: "Comprovante de Rendimentos", subtitulo: "comprovante de rendimentos", page: "rendimentos", icon: "assets/icon/home/rendimentos.svg", acao: callbackAbrirPagina, perfisAcesso: [], atendente: false, isSubmenuServidor: true, type: MenuType.MAIN });
			menusItem.push({ titulo: "Educação Previdenciária", subtitulo: "", page: "educacao", icon: "assets/icon/home/educacao.svg", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true, isSubmenuRecadastramento: false, type: MenuType.SECONDARY });
		}
		if (isMenuLateral) {
			let menuLateral: MenuItem[] = [];
			menuLateral.push({ titulo: "Início", page: "home", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
			
			// Constrói menu com itens do servidor
			let submenuServidor = menusItem.filter((m) => m.isSubmenuServidor) || [];
			if (submenuServidor.length > 0) {
				menuLateral.push({ titulo: "Servidor", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true, subPages: submenuServidor, isOpen: false });
			}

			// Constrói menu com itens do recadastramento
			let submenuRecadastramento = menusItem.filter((m) => m.isSubmenuRecadastramento) || [];
			if (submenuRecadastramento.length > 0) {
				submenuRecadastramento.push({ titulo: "Histórico", page: "tabs-recadastramento", params: { segment: "historico" }, acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
				submenuRecadastramento.push({ titulo: "Pendências", page: "pendencias", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
				menuLateral.push({ titulo: "Dados cadastrais", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true, subPages: submenuRecadastramento, isOpen: false });
			}

			menuLateral.push({ titulo: "Notificações", page: "mensagens", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
			menuLateral.push({ titulo: "Sobre", page: "sobre", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
			menuLateral.push({ titulo: "Termos de Uso", page: "termos", acao: callbackAbrirPagina, perfisAcesso: [], atendente: true });
			return menuLateral;
		} else {
			return menusItem;
		}
	}

	async obterDados(): Promise<void> {
		try {
			this.dados = await this.recadastramentoService.obterDadosCadastro(this.usuario.upn);
		} catch (error) {
			console.error(error);
			if (this.usuario.upn != environment.globalParams.cpfTesteApple) {
				throw error;
			}
		}
	}

	async obterPerfil(cpf: string, salvar: boolean): Promise<PerfilUsuario> {
		const perfil: any = await this.remoteService.getData(WEBSERVICE.OBTER_PERFIL + cpf);
		if (perfil && salvar) {
			this.perfilUsuario = perfil;
			this.encrypt("perfil", perfil, true);
		}
		return perfil;
	}

	async login(cpf: string): Promise<void> {
		const redirectUri: string = this.platform.is("capacitor") || location.href.indexOf("http://localhost") > -1 ? environment.authConfig.params.redirect_uri : environment.authConfig.params.redirect_uri_pwa;

		const state: string = new Date().getTime().toString();
		localStorage.setItem(StorageKeys.PKCE_STATE, state);

		const codeVerifier: string = PKCEHelper.generateRandomString();
		localStorage.setItem(StorageKeys.PKCE_CODE_VERIFIER, codeVerifier);

		const codeChallenge: string = await PKCEHelper.pkceChallengeFromVerifier(codeVerifier);

		const urlCentral: string = PKCEHelper.buildUrlQueryParams({
			response_type: environment.authConfig.params.response_type,
			client_id: environment.authConfig.params.client_id,
			redirect_uri: redirectUri,
			code_challenge: codeChallenge,
			code_challenge_method: "S256",
			state: state,
			attribute: UtilHelper.unmask(cpf),
			force_login: true
		}, environment.authConfig.path + "authorize/jwt");

		localStorage.setItem(StorageKeys.ULTIMO_CPF, cpf);

		if (this.platform.is("capacitor")) {
			const browserRef: InAppBrowserObject = this.iab.create(urlCentral, "_blank", "location=no,clearsessioncache=yes,clearcache=yes");
			browserRef.on("loadstart").subscribe(async (event) => {
				this.checkAuth(event.url, browserRef);
			});
		} else {
			window.open(urlCentral, "_self");
		}
	}

    async checkAuth(url: string, browserRef?: InAppBrowserObject) {
		const redirectUri: string = this.platform.is("capacitor") || location.href.indexOf("http://localhost") > -1 ? environment.authConfig.params.redirect_uri : environment.authConfig.params.redirect_uri_pwa;
        if ((url).indexOf(redirectUri + "?") > -1) {
            browserRef?.close();
            const response: any = PKCEHelper.parseAuthCodeCentral(url);
            if (response && response.code && response.state == localStorage.getItem(StorageKeys.PKCE_STATE)) {
                const tokenResponse: any = await this.obterTokenCentralCodAutorizacao(response.code);
				if (tokenResponse) {
					const usuario: any = jwt_decode(tokenResponse.access_token);
					console.log(usuario);
					this.salvarUsuario(usuario);
					await this.obterPerfil(usuario.upn, true);
					this.eventService.publish(AppEvents.LOGIN_APP, usuario);
                } else {
                	throw new Error("Erro na autenticação, tente novamente.");
                }
            }
        }
    }

	async obterTokenCentralCodAutorizacao(code: string): Promise<any> {
		try {
			const redirectUri: string = document.URL.indexOf("localhost") > -1 ? environment.authConfig.params.redirect_uri : environment.authConfig.params.redirect_uri_pwa;
			const params: any = {
				grant_type: "authorization_code",
				client_id: environment.authConfig.params.client_id,
				redirect_uri: redirectUri,
				code: code,
				state: localStorage.getItem(StorageKeys.PKCE_STATE),
				code_verifier: localStorage.getItem(StorageKeys.PKCE_CODE_VERIFIER),
				attribute: localStorage.getItem(StorageKeys.ULTIMO_CPF) || ''
			};
			console.log(params);
			const authEncoded: string = btoa(environment.authConfig.params.client_id + ":" + "null");
			this.remoteService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
			this.remoteService.addParamsHeader('Authorization', "Basic " + authEncoded);
			const tokenResponse: any = await this.remoteService.postData(AUTH_END_POINT + "token/jwt", params);
			if (tokenResponse) {
				if (tokenResponse.access_token) {
					this.remoteService.setAuthToken(tokenResponse.access_token);
					this.encrypt("token", tokenResponse.access_token);
				}
				if (tokenResponse.refresh_token) {
					this.encrypt("refreshToken", tokenResponse.refresh_token);
				}
			}
			console.log(tokenResponse);
			return tokenResponse;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	/**
	 * O fluxo de refresh token com PKCE não foi implementado na Central.
	 * Em conversa com a equipe da Central, esse fluxo não é recomendado e não existe para o PKCE.
	 * Sendo assim, a alternativa sugerida foi para chamar a tela da Central, pois enquanto o tempo de vida do refresh token existir,
	 * o comportamento será semelhante, e a sessão da Central irá retornar um novo code para trocar por um novo access token.
	 * No app, foi implementado esse fluxo apenas para versão web desktop, pois para não quebrar a navegação, foi feito com popup,
	 * o que não funciona muito bem para mobile. Além disso, isso possivelmente será mais útil e utilizado para os atendentes que utilizarão um desktop.
	 * Assim, esse método então chama a Central em uma popup (não quebrando a navegação), trata o code/token se existir e retorna para o serviço
	 * que o chamou no remote service, para refazer a chamada com o novo token válido
	 */
	async refreshToken(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (!this.platform.is("desktop")) {
				reject(new ErroApp(401, 'Sessão expirada! Efetue login novamente.'));
				return;
			}
			const redirectUri: string = location.href.indexOf("http://localhost") > -1 ? environment.authConfig.params.redirect_uri : environment.authConfig.params.redirect_uri_pwa;

			const state: string = new Date().getTime().toString();
			localStorage.setItem("pkce_state", state);

			const codeVerifier: string = PKCEHelper.generateRandomString();
			localStorage.setItem("pkce_code_verifier", codeVerifier);

			// Hash and base64-urlencode the secret to use as the challenge
			const codeChallenge: string = await PKCEHelper.pkceChallengeFromVerifier(codeVerifier);

			const urlCentral: string = PKCEHelper.buildUrlQueryParams({
				response_type: "code",
				client_id: environment.authConfig.params.client_id,
				redirect_uri: redirectUri,
				code_challenge: codeChallenge,
				code_challenge_method: "S256",
				state: state
			}, environment.authConfig.path + "authorize/jwt");

			let oauthWindow: Window = UtilHelper.popupWindow(urlCentral, 'CentralOAuth', window, 400, 600);
			let oldHref: string = "";
			let userClosedWindow: boolean = true;
			const oauthInterval: any = window.setInterval(async () => {
				try {
					if (!oauthWindow) {
						alert("Pop-up (janela) foi bloqueado. Por favor, desbloqueie o pop-up do navegador (verifique o canto superior direito) para podermos recuperar a sua sessão do App PARANAPREVIDENCIA");
						oauthWindow = UtilHelper.popupWindow(urlCentral, 'CentralOAuth', window, 400, 600);
					} else {
						if (oauthWindow.closed) {
							window.clearInterval(oauthInterval);
							if (userClosedWindow) {
								reject(new ErroApp(401, 'Sessão expirada! Efetue login novamente.'));
							}
						}
						const oauthWindowHref: string = oauthWindow.location.href || "";
						if (oldHref != oauthWindowHref) {
							oldHref = oauthWindowHref;
							if (oauthWindowHref && oauthWindowHref.indexOf(redirectUri) > -1) {
								userClosedWindow = false;
								oauthWindow.close();
								const response: any = PKCEHelper.parseAuthCodeCentral(oauthWindowHref);
								if (response && response.code && response.state == localStorage.getItem("pkce_state")) {
									const token: any = await this.obterTokenCentralCodAutorizacao(response.code);
									if (token) {
										let usuario = jwt_decode(token.access_token);
										if (usuario) {
											this.salvarUsuario(this.usuario);
											await this.obterPerfil(this.usuario.upn, true);
										}
										resolve(token);
									} else {
										reject(new ErroApp(401, 'Sessão expirada! Efetue login novamente.'));
									}
								} else {
									reject(new ErroApp(401, 'Sessão expirada! Efetue login novamente.'));
								}
							}
						}
					}
				} catch (error) {
					console.error(error);
					if (oauthWindow) {
						userClosedWindow = false;
						oauthWindow.close();
					}
					reject(new ErroApp(401, 'Sessão expirada! Efetue login novamente.'));
				}
			}, 500);
		});
	}

	async previsaAceitarTermos(cpf: string): Promise<boolean> {
		const aceitouTermosResult: GetResult = await Preferences.get({ key: "aceiteTermo-" + cpf });
		if (!aceitouTermosResult || !aceitouTermosResult.value) return true;

		const aceitouTermos = JSON.parse(aceitouTermosResult.value);

		const result: GetResult = await Preferences.get({ key: "termos" });
		if (result && result.value) {
			const termos = JSON.parse(result.value);
			return aceitouTermos.versao < termos.versao;
		}
		return true;
	}

	// PUSH NOTIFICATION
	// -------------------------------

	async obterDevicePushToken(): Promise<DevicePush> {
		try {
			const result: GetResult = await Preferences.get({ key: "devicePushToken" });
			if (result && result.value) {
				return JSON.parse(result.value);
			}
		} catch (error) {
			console.log(error);
		}
		return null;
	}

	async salvarDevicePushToken(devicePush: DevicePush): Promise<void> {
		try {
			console.log(JSON.stringify(devicePush));
			await this.remoteService.postData(WEBSERVICE.CADASTRAR_DEVICE_PUSH, devicePush);
			console.log("push token registrado no servidor");
			await Preferences.set({
				key: "devicePushToken",
				value: JSON.stringify(devicePush)
			});
		} catch (error) {
			console.log(error);
		}
	}

	async removerDevicePushToken(devicePush: DevicePush): Promise<void> {
		try {
			const urlRequest: string = this.remoteService.buildUrlPathParams({ token: devicePush.token }, WEBSERVICE.REVOGAR_DEVICE_PUSH);
			await this.remoteService.putData(urlRequest);
			console.log("push token removido no servidor: " + devicePush.token);
			await Preferences.remove({ key: "devicePushToken" });
		} catch (error) {
			console.log(error);
		}
	}

	// ENCRYPT
	// -------------------------------

	async encrypt(chave: string, valor: any, shouldParse?: boolean): Promise<void> {
		if (shouldParse) {
			valor = JSON.stringify(valor);
		}
		const encrypted: any = aes.encrypt(valor, UsuarioService.KEY).toString();
		await Preferences.set({
			key: chave,
			value: encrypted || valor
		});
	}

	async decrypt(chave: string, shouldParse?: boolean): Promise<any> {
		try {
			const encrypted: any = await Preferences.get({ key: chave });
			if (encrypted && encrypted.value) {
				const bytes: any = aes.decrypt(encrypted.value, UsuarioService.KEY);
				if (bytes) {
					if (shouldParse) {
						return JSON.parse(bytes.toString(Utf8));
					}
					return bytes.toString(Utf8);
				}
			}
		} catch (erro) {
			console.error(erro);
		}
		return null;
	}
}
