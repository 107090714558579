import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { WEBSERVICE } from 'src/app/commom/constants';
import { AcaoDependente } from 'src/app/enums/acao-dependente.enum';
import { GrupoDados } from 'src/app/enums/grupo-dados.enum';
import { TipoRecadastramento } from 'src/app/enums/tipo-recadastramento.enum';
import { Dependente } from 'src/app/models/dependente.model';
import { DocumentoPendente, Pendencia } from 'src/app/models/documento-pendente.model';
import { Documento } from 'src/app/models/documento.model';
import { Historico } from 'src/app/models/historico.model';
import { Item } from 'src/app/models/item.model';
import { OperacaoDependente } from 'src/app/models/operacao-dependente.model';
import { Recadastramento } from 'src/app/models/recadastramento.model';
import { RepresentanteLegal } from 'src/app/models/representante-legal.model';
import { ResumoRecadastramento } from 'src/app/models/resumo-recadastramento.model';
import { RemoteService } from './remote.service';

@Injectable({
	providedIn: 'root'
})
export class RecadastramentoService {

	constructor(
		private platform: Platform,
		private remoteService: RemoteService
	) { }

	async obterDadosCadastro(cpf: string): Promise<Recadastramento> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.OBTER_DADOS_RECADASTRAMENTO);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterResumoRecadastramento(cpf: string): Promise<ResumoRecadastramento> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.OBTER_RESUMO_RECADASTRAMENTO);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterListaEstadoCivilPorCpf(cpf: string): Promise<Array<Item>> {
		return await this.remoteService.getData(WEBSERVICE.LISTAR_ESTADO_CIVIL + "/" + cpf) || [];
	}

	async obterOperacoesDependente(cpf: string, estadoCivil: string, novoEstadoCivil: string, motivoExclusao?: Item): Promise<Array<OperacaoDependente>> {
		let urlRequisicao: string = this.remoteService.buildUrlPathParams({
			cpf: cpf,
			estadocivilantigo: estadoCivil,
			estadocivilnovo: novoEstadoCivil
		}, WEBSERVICE.OBTER_OPERACOES_DEPENDENTE);

		if (motivoExclusao) {
			urlRequisicao += "?motivoexclusao=" + motivoExclusao.codigo;
		}
		return await this.remoteService.getData(urlRequisicao);
	}

	async mostrarPerguntaRecebeRenda(cpf: string): Promise<"S" | "N"> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.MOSTRAR_PERGUNTA_RECEBE_RENDA);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterDadosDependente(cpf: string): Promise<Array<Dependente>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.OBTER_DADOS_DEPENDENTE);
		const response: Array<Dependente> = await this.remoteService.getData(urlRequisicao);
		const dependentes: Array<Dependente> = [];
		if (response && response.length > 0) {
			response.forEach((d) => {
				if (!d.deveSer || d.deveSer != AcaoDependente.EXCLUIR) {
					dependentes.push(new Dependente(d));
				}
			});
		}
		return dependentes;
	}

	async obterMotivosExclusaoDependente(tipoDependente: string): Promise<Array<Item>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ tipoDependente: tipoDependente }, WEBSERVICE.OBTER_MOTIVOS_EXCLUSAO_DEPENDENTE);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterDocumentosExclusaoDependente(motivo: Item): Promise<Array<Documento>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ motivo: motivo.codigo }, WEBSERVICE.DOCUMENTOS_EXCLUSAO_DEPENDENTE);
		const resp = await this.remoteService.getData(urlRequisicao);
		const documentos: Array<Documento> = [];
		if (resp && resp.length > 0) {
			resp.forEach((d) => {
				documentos.push(new Documento(GrupoDados.DEPENDENTE, d));
			})
		}
		return documentos;
	}

	async obterDocumentosInclusaoDependente(tipoDependente: string, cpfDependenteParaIncluir: string, rgDoParana: "S" | "N", datanascimento?: string, invalido?: "S" | "N", universitario?: "S" | "N"): Promise<Array<Documento>> {
		let urlRequisicao: string = this.remoteService.buildUrlPathParams({
			tipoDependente: tipoDependente,
			rgDoParana: rgDoParana
		}, WEBSERVICE.DOCUMENTOS_INCLUSAO_DEPENDENTE);

		if (datanascimento || invalido || universitario) {
			urlRequisicao += "?";
			urlRequisicao = this.remoteService.buildUrlQueryParams({
				datanascimento: datanascimento,
				invalido: invalido,
				universitario: universitario
			}, urlRequisicao);
		}
		console.log(urlRequisicao);

		const resp = await this.remoteService.getData(urlRequisicao);
		const documentos: Array<Documento> = [];
		if (resp && resp.length > 0) {
			resp.forEach((d: Documento) => {
				// Esse cpf é utilizado na etapa da validação facial, para obter corretamente o codigoCatalogo do dependente correto para envio de documentos
				d.cpfDependenteParaIncluir = cpfDependenteParaIncluir;
				documentos.push(new Documento(GrupoDados.DEPENDENTE, d));
			})
		}
		return documentos;
	}

	async obterDocumentos(
		cpf: string, 
		alterouEstadoCivil: "S" | "N", 
		novoEstadoCivil?: Item, 
		receberenda?: "S" | "N", 
		possuiescriturauniaoestavel?: "S" | "N",
		representante?: "S" | "N"
	): Promise<Array<Documento>> {
		let urlRequisicao: string = this.remoteService.buildUrlPathParams({
			cpf: cpf,
			alterouEstadoCivil: alterouEstadoCivil
		}, WEBSERVICE.OBTER_DOCUMENTOS);

		let queryParams: any = {
			estadocivilnovo: alterouEstadoCivil == "S" && novoEstadoCivil ? novoEstadoCivil.codigo : null,
			receberenda: receberenda,
			possuiescriturauniaoestavel: possuiescriturauniaoestavel || null,
			representante: representante || "N"
		}
		urlRequisicao = this.remoteService.buildUrlQueryParams(queryParams, urlRequisicao);
		const resp = await this.remoteService.getData(urlRequisicao);
		const documentos: Array<Documento> = [];
		if (resp && resp.length > 0) {
			resp.forEach((d) => {
				documentos.push(new Documento(GrupoDados.RECADASTRAMENTO, d));
			})
		}
		return documentos;
	}

	async obterPendencias(cpf: string): Promise<Array<Pendencia>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.OBTER_PENDENCIAS);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterVinculosDependente(cpf: string, isFluxoDependente: boolean): Promise<Array<Item>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({
			cpf: cpf,
			teladependente: isFluxoDependente ? "S" : "N"
		}, WEBSERVICE.OBTER_VINCULOS_DEPENDENCIA);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterListaEstadoCivilDependente(vinculo: string): Promise<any> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ vinculodependencia: vinculo }, WEBSERVICE.LISTA_ESTADO_CIVIL_DEPENDENTES);
		return await this.remoteService.getData(urlRequisicao);
	}

	async verificaSeNecessarioEnviarDocumentosPensionista(catalogo: string): Promise<any> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ catalogo: catalogo }, WEBSERVICE.VERIFICA_SE_NECESSARIO_ENVIAR_DOCUMENTOS_EPROTOCOLO);
		return await this.remoteService.getData(urlRequisicao);
	}

	async obterDeclaracoes(cpf: string, recebeRenda?: "S" | "N"): Promise<string> {
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({
			cpf: cpf,
			recebeRenda: recebeRenda ?? "N"
		}, WEBSERVICE.LISTAR_DECLARACAO);

		return await this.remoteService.getData(urlRequisicao);
	}

	async gerouEprotocolo(cpf: string): Promise<"S" | "N"> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpf }, WEBSERVICE.GEROU_EPROTOCOLO);
		return await this.remoteService.getData(urlRequisicao);
	}

	async inserirDadosRecadastramento(dadosRecadastramentoJson: any): Promise<any> {
		if (dadosRecadastramentoJson.documentosNecessarios) {
			delete dadosRecadastramentoJson.documentosNecessarios;
		}
		console.log(dadosRecadastramentoJson);
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		return await this.remoteService.putData(WEBSERVICE.INSERIR_DADOS_RECADASTRAMENTO, dadosRecadastramentoJson);
	}

	async finalizarRecadastramento(catalogo: string, atualizadoPeloRepresentante: "S" | "N", reenvioDocumentos?: boolean): Promise<any> {
		let formaRealizacao: string = 'APP';
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
			formaRealizacao = 'PWA';
		}
		let url: string = `${WEBSERVICE.FINALIZAR_RECADASTRAMENTO}/${catalogo}/${formaRealizacao}/${atualizadoPeloRepresentante}`;
		if (reenvioDocumentos) {
			url += "?reenvioDocumentos=S";
		}
		return await this.remoteService.postData(url);
	}

	async validarDependente(dependente: Dependente, acao?: AcaoDependente): Promise<any> {
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		const dados: any = this.prepararDependente(dependente, acao);
		return await this.remoteService.postData(dados.urlRequisicao, dados.dependente);
	}

	async enviarDadosDependente(dependente: Dependente, acao?: AcaoDependente): Promise<any> {
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		const dados: any = this.prepararDependente(dependente, acao);
		return await this.remoteService.putData(WEBSERVICE.INSERIR_DADOS_DEPENDENTE, dados.dependente);
	}

	async anexarDocumento(catalogo: string, grupo: GrupoDados, documento: Documento | DocumentoPendente): Promise<any> {
		let urlServico: string;
		switch (grupo) {
			case GrupoDados.DEPENDENTE:
				urlServico = WEBSERVICE.ANEXAR_DOCUMENTO_DEPENDENTE;
				break;

			case GrupoDados.REPRESENTANTE_LEGAL:
				urlServico = WEBSERVICE.ANEXAR_DOCUMENTO_REPRESENTANTE_LEGAL;
				break;

			default:
				urlServico = WEBSERVICE.ANEXAR_DOCUMENTO_RECADASTRAMENTO;
				break;
		}
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ catalogo: catalogo, codigodocumento: documento.codigo }, urlServico);

		if (this.platform.is("capacitor")) {
			return await this.remoteService.uploadNativeFile(urlRequisicao, documento.arquivo.urlNativo, "arquivo");
		} else {
			const formData: FormData = new FormData();
			formData.append("arquivo", documento.arquivo.pwaFile);

			this.remoteService.addParamsHeader("responseType", "text");
			return await this.remoteService.uploadFile(urlRequisicao, formData);
		}
	}

	private prepararDependente(dependente: Dependente, acao?: AcaoDependente): any {
		let urlRequisicao: string = WEBSERVICE.VALIDAR_ALTERACOES_DEPENDENTE;
		let dadosDependente: any;

		// Se não tem ação, é inserção
		if (!acao || acao == AcaoDependente.INCLUIR) {
			dadosDependente = dependente.toJsonIncluir();
		} else {
			dependente.deveSer = acao;
			if (acao == AcaoDependente.EXCLUIR) {
				urlRequisicao = WEBSERVICE.VALIDAR_EXCLUSAO_DEPENDENTE;
				dadosDependente = dependente.toJsonExcluir();
			} else {
				dadosDependente = dependente.toJsonAlterar();
			}
		}
		return {
			urlRequisicao: urlRequisicao,
			dependente: dadosDependente
		};
	}

	// REPRESENTANTE LEGAL
	// ---------------------------------

	async obterDadosRepresentanteLegal(cpfServidor: string): Promise<RepresentanteLegal> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ cpf: cpfServidor	}, WEBSERVICE.OBTER_DADOS_REPRESENTANTE_LEGAL);
		const response: any = await this.remoteService.getData(urlRequisicao);
		if (response && response.codigoCatalogo) {
			return new RepresentanteLegal(response);
		}
		return null;
	}

	async obterTiposRepresentanteLegal(): Promise<Array<Documento>> {
		return await this.remoteService.getData(WEBSERVICE.TIPOS_REPRESENTANTE_LEGAL);
	}

	async obterDocumentosRepresentanteLegal(tipoRepresentante: string): Promise<Array<Documento>> {
		const urlRequisicao: string = this.remoteService.buildUrlPathParams({ tiporepresentante: tipoRepresentante }, WEBSERVICE.DOCUMENTOS_REPRESENTANTE_LEGAL);
		const resp = await this.remoteService.getData(urlRequisicao);
		const documentos: Array<Documento> = [];
		if (resp && resp.length > 0) {
			resp.forEach((d) => {
				documentos.push(new Documento(GrupoDados.REPRESENTANTE_LEGAL, d));
			})
		}
		return documentos;
	}

	async enviarDadosRepresentanteLegal(dados: RepresentanteLegal, alterouRepresentante: boolean): Promise<any> {
		if (!this.platform.is("capacitor")) {
			this.remoteService.addParamsHeader("responseType", "text");
		}
		return await this.remoteService.putData(WEBSERVICE.INSERIR_DADOS_REPRESENTANTE_LEGAL, dados.toJson(alterouRepresentante));
	}

	// HISTÓRICO
	// -------------------------------------

	async consultaAnosHistorico(cpf: string): Promise<string[]> {
		let response = await this.remoteService.getData(WEBSERVICE.LISTAR_ANOS_HISTORICO + cpf);
		if (response && response.length > 0) {
			response.sort((a1, a2) => {
				return a1 < a2 ? 1 : -1;
			});
		}
		return response || [];
	}

	async consultarHistorico(ano: string, cpf: string, tipo: TipoRecadastramento): Promise<Historico> {
		const urlRequest: string = `${WEBSERVICE.HISTORICO_RECADASTRAMENTO}${cpf}/${ano}?tipo=${tipo}`;
		return await this.remoteService.getData(urlRequest);
	}

	async consultaHistoricoCompleto(ano: string, cpf: string, tipo: TipoRecadastramento): Promise<any> {
		const urlRequest: string = `${WEBSERVICE.HISTORICO_COMPLETO}${cpf}/${ano}?tipo=${tipo}`;
		const result: any = await this.remoteService.getData(urlRequest);
		if (result) {
			// Faz o encode corretamente.. algumas vezes está vindo no final da string \u003d causando erro ao converter para arquivo pdf
			return decodeURIComponent(JSON.parse('"' + result + '"'));
		}
		return result;
	}
}
