import { isPlatform } from '@ionic/core';
import { environment } from 'src/environments/environment';
import { TipoServidor } from '../enums/tipo-servidor.enum';
import { VersionCompare } from '../enums/version-compare.enum';
import { Certidao } from '../models/certidao.model';
import { Item } from '../models/item.model';
import { PerfilUsuario } from '../models/perfil-usuario.model';

export class UtilHelper {

	public static getEnumByCod(codigo: any, obj: any): any {
		const key: string = Object.keys(obj).find((k: string) => {
			return obj[k].codigo === codigo;
		});
		return obj[key];
	}

	public static parseTokenCentral(url: string): any {
		try {
			const responseParameters: Array<string> = ((url).split("#")[1]).split("&");
			const parsedResponse: any = { };
			for (const param of responseParameters) {
				parsedResponse[param.split("=")[0]] = param.split("=")[1];
			}
			if (parsedResponse["access_token"] && parsedResponse["access_token"]) {
				return parsedResponse;
			}
			return null;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	public static async detectarCamera(): Promise<boolean> {
		try {
			const md: MediaDevices = navigator.mediaDevices;
			let results: boolean = false;

			if (!md || !md.enumerateDevices) results = false;

			const devices: MediaDeviceInfo[] = await md.enumerateDevices();
			results = devices.some(device => 'videoinput' === device.kind);

			return results;

		} catch (error) { return false; }
	}

	public static isDevMode(): boolean {
		return !isPlatform("capacitor") && location.href.indexOf("http://localhost") > -1;
	}

	public static podeAcessarApp(perfil: PerfilUsuario, cpf: string): boolean {
		const cpfSemMascara: string = UtilHelper.unmask(cpf);
		if (cpfSemMascara == environment.globalParams.cpfTesteApple) {
			return true;
		}
		if (!perfil) {
			return false;
		}
		if (perfil.atendeBalcao == "S") {
			return true;
		}
		if (perfil.tipoServidor == TipoServidor.NAO_SERVIDOR || (perfil.tipoServidor == TipoServidor.ATIVO_ESTADO && perfil.ehAposentado == "N")) {
			return false;
		}
		return true;
	}

	public static isTelefoneNumerosIguais(numero: string): boolean {
		const numerosRejeitadosTelefone: Array<string> = [
			"00000000",
			"11111111",
			"22222222",
			"33333333",
			"44444444",
			"55555555",
			"66666666",
			"77777777",
			"88888888",
			"99999999",
		];
		const numerosRejeitadosCelular: Array<string> = [
			"000000000",
			"111111111",
			"222222222",
			"333333333",
			"444444444",
			"555555555",
			"666666666",
			"777777777",
			"888888888",
			"999999999",
		];
		const somenteNumero: string = UtilHelper.unmask(numero);
		const lista: Array<string> = somenteNumero.length > 8 ? numerosRejeitadosCelular : numerosRejeitadosTelefone;
		for (let n of lista) {
			if (somenteNumero.startsWith(n)) {
				return true;
			}
		}
		return false;
	}

	public static popupWindow(url: string, windowName: string, win: Window, w: number, h: number): Window {
		const y: number = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
		const x: number = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
		return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
	}

	public static async converterUrlOuBase64ParaArquivo(urlOuBase64: string, nomeArquivo: string, tipo: string): Promise<File> {
		const res: Response = await fetch(urlOuBase64);
		const blob: Blob = await res.blob();
		return new File([blob], nomeArquivo, { type: tipo });
	}

	public static renomearArquivoWeb(file: File, extensao: string): File {
		return new File([file], "Arquivo-" + new Date().getTime() + "." + extensao, { type: file.type });
	}

	public static downloadFileFromBase64(bytesBase64: string, mimeType: string, fileName: string): void {
		const fileUrl: string = "data:" + mimeType + ";base64," + bytesBase64;
		fetch(fileUrl)
			.then(response => response.blob())
			.then(blob => {
				const downloadLink: HTMLAnchorElement = window.document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(blob);
				downloadLink.download = fileName;
				downloadLink.click();
			});
	}

	public static isImage(nameArquivo: string): boolean {
		const extensaoImagens: string[] = [".png", ".jpg", ".jpeg"];
		return extensaoImagens.some((ext) => {
			return nameArquivo.indexOf(ext) !== -1;
		});
	}

	public static unmask(valor: string): any {
		if (!valor) return "";
		return valor.replace(/\D+/g, '');
	}

	public static capitalizeName(name: string): string {
		if (!name) return "";
		return name.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
	}

	/**
	 * Compara versões
	 * @param versaoLocal
	 * @param versaoRemota
	 */
	 public static comparaVersao(versaoLocal: string, versaoRemota: string): VersionCompare {
		if (versaoLocal === versaoRemota) {
			return VersionCompare.VERSOES_IGUAIS;
		}

		const local_components: Array<string> = versaoLocal.split(".");
		const remote_components: Array<string> = versaoRemota.split(".");

		const len: number = Math.min(local_components.length, remote_components.length);

		// loop while the components are equal
		for (let i: number = 0; i < len; i++) {

			// Local maior que remoto
			if (parseInt(local_components[i]) > parseInt(remote_components[i])) {
				return VersionCompare.VERSAO_LOCAL_MAIOR;
			}

			// Remoto maior que local
			if (parseInt(local_components[i]) < parseInt(remote_components[i])) {
				return VersionCompare.VERSAO_REMOTA_MAIOR;
			}
		}

		// If one's a prefix of the other, the longer one is greater.
		if (local_components.length > remote_components.length) {
			return VersionCompare.VERSAO_LOCAL_MAIOR;
		}

		if (local_components.length < remote_components.length) {
			return VersionCompare.VERSAO_REMOTA_MAIOR;
		}

		// Otherwise they are the same.
		return VersionCompare.VERSOES_IGUAIS;
	}

	public static obterEstados(): Array<Item> {
		return [
			{ codigo: "PR", descricao: "Paraná" },
			{ codigo: "AC", descricao: "Acre" },
			{ codigo: "AL", descricao: "Alagoas" },
			{ codigo: "AP", descricao: "Amapá" },
			{ codigo: "AM", descricao: "Amazonas" },
			{ codigo: "BA", descricao: "Bahia" },
			{ codigo: "CE", descricao: "Ceará" },
			{ codigo: "DF", descricao: "Distrito Federal" },
			{ codigo: "ES", descricao: "Espírito Santo" },
			{ codigo: "GO", descricao: "Goiás" },
			{ codigo: "MA", descricao: "Maranhão" },
			{ codigo: "MT", descricao: "Mato Grosso" },
			{ codigo: "MS", descricao: "Mato Grosso do Sul" },
			{ codigo: "MG", descricao: "Minas Gerais" },
			{ codigo: "PA", descricao: "Pará" },
			{ codigo: "PB", descricao: "Paraíba" },
			{ codigo: "PE", descricao: "Pernambuco" },
			{ codigo: "PI", descricao: "Piauí" },
			{ codigo: "RJ", descricao: "Rio de Janeiro" },
			{ codigo: "RN", descricao: "Rio Grande do Norte" },
			{ codigo: "RS", descricao: "Rio Grande do Sul" },
			{ codigo: "RO", descricao: "Rondônia" },
			{ codigo: "RR", descricao: "Roraima" },
			{ codigo: "SC", descricao: "Santa Catarina" },
			{ codigo: "SP", descricao: "São Paulo" },
			{ codigo: "SE", descricao: "Sergipe" },
			{ codigo: "TO", descricao: "Tocantins" }
		];
	}

	public static extraiCamposMatriculaCertidao(certidao: Certidao): void {
		if (!certidao.nrMatricula) {
			return;
		}
		if (certidao.nrMatricula.match(/^[0-9]{6}\s[0-9]{2}\s[0-9]{2}\s[0-9]{4}\s[0-9]{1}\s[0-9]{5}\s[0-9]{3}\s[0-9]{7}\s[0-9]{2}$/) != null) {
			// Extrai da Matrícula do trecho referente ao Número da Certidão: (30-37)
			certidao.nrCertidao = certidao.nrMatricula.substr(30, 7);

			// Extrai da Matrícula do trecho referente ao Livro da Certidão: (20-25)
			certidao.nrLivro = certidao.nrMatricula.substr(20, 5);

			// Extrai da Matrícula do trecho referente a Folha da Certidão: (20-25)
			certidao.nrFolha = certidao.nrMatricula.substr(26, 3);
		} else {
			certidao.nrCertidao = "";
			certidao.nrLivro = "";
			certidao.nrFolha = "";
		}
	}
}
