import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import jwt_decode from 'jwt-decode';
import { AUTH_END_POINT } from 'src/app/commom/constants';
import { ErroApp } from 'src/app/models/erro.model';
import { environment } from 'src/environments/environment';
import { UsuarioService } from './usuario.service';

export type Request = (path: string, params: any, headers: any) => any;

@Injectable({
	providedIn: 'root'
})
export class RemoteService {
	private header: any;
	private token: string;
	private connected: boolean;
	private usuarioService: UsuarioService;
	private http: {
		get?: Request,
		post?: Request,
		put?: Request,
		delete?: Request,
		download?: Request
	};

	constructor(
		private httpNative: HTTP,
		private httpClient: HttpClient,
		private platform: Platform,
		private network: Network
	) {
		this.http = {
			get: async (path: string, params: any, headers: any) => {
				const url: string = encodeURI((path || '').trim());
				if (this.platform.is('capacitor')) {
					await this.configNativeClient(headers);
					return await this.httpNative.get(url, params, headers);
				} else { return await this.httpClient.get(url, { headers, params, responseType: this.getBrowserResponseType(headers) }).toPromise<any>(); }
			},
			post: async (path: string, body: any, headers: any) => {
				const url: string = encodeURI((path || '').trim());
				if (this.platform.is('capacitor')) {
					await this.configNativeClient(headers);
					return await this.httpNative.post(url, body, headers);
				} else {
					body = this.browserSerializeFormUrlEncoded(headers, body);
					return await this.httpClient.post(url, body, { headers, responseType: this.getBrowserResponseType(headers) }).toPromise<any>();
				}
			},
			put: async (path: string, body: any, headers: any) => {
				const url: string = encodeURI((path || '').trim());
				if (this.platform.is('capacitor')) {
					await this.configNativeClient(headers);
					return await this.httpNative.put(url, body, headers);
				} else {
					body = this.browserSerializeFormUrlEncoded(headers, body);
					return await this.httpClient.put(url, body, { headers, responseType: this.getBrowserResponseType(headers) }).toPromise<any>();
				}
			},
			delete: async (path: string, params: any, headers: any) => {
				const url: string = encodeURI((path || '').trim());
				if (this.platform.is('capacitor')) {
					await this.configNativeClient(headers);
					return await this.httpNative.delete(url, params, headers);
				} else { return await this.httpClient.delete(url, { headers, params, responseType: this.getBrowserResponseType(headers) }).toPromise<any>(); }
			},
			download: async (path: string, destFilename: string, headers: any) => {
				if (this.platform.is('capacitor')) {
					return await this.httpNative.downloadFile(path, { }, headers, destFilename);
				} else { return await this.httpClient.get(path, { headers, responseType: 'blob' as 'json' }).toPromise<any>(); }
			}
		};
		this.connected = true;
		this.platform.ready().then(() => {
			this.configure();
		});
	}

	configure(): void {
		if (this.platform.is('capacitor')) {
			this.connected = this.network.type !== 'none';
		}
		this.network.onConnect().subscribe(() => {
			this.connected = true;
		});
		this.network.onDisconnect().subscribe(() => {
			this.connected = false;
		});
	}

	//Para contornar erros de circular dependency
	setUserService(service: UsuarioService): void {
		this.usuarioService = service;
	}

	setAuthToken(accessToken: string): void {
		if (accessToken && accessToken.trim().length > 0) {
			this.token = accessToken.trim();
		} else {
			this.token = null;
		}
	}

	async getData(url: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const getRequest: VoidFunction = async () => {
					try {
						const resp: any = await this.http.get(url, { }, await this.getRequestOptions(url));
						console.log("---->>> getData() de ", url);
						console.log("---->>> response do getData() acima ", resp);
						this.header = null;
						if (!resp || resp.status < 200 || resp.status > 299) throw new Error('Falha ao obter os dados ao serviço remoto.');
						resolve(this.formatResponse(resp));
					} catch (error) {
						this.catchError(error, url, getRequest, reject);
					}
				};
				getRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async putData(url: string, dados?: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const putRequest: VoidFunction = async () => {
					try {
						console.log(url, dados);
						const resp: any = await this.http.put(url, dados || { }, await this.getRequestOptions(url));
						console.log("---->>> putData() de ", url);
						console.log("---->>> dados do put acima", dados);
						console.log("---->>> response do put acima ", resp);
						this.header = null;
						if (!resp || resp.status < 200 || resp.status > 299) throw new Error('Falha ao enviar os dados ao serviço remoto.');
						resolve(this.formatResponse(resp));
					} catch (error) {
						this.catchError(error, url, putRequest, reject, dados);
					}
				};
				putRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async postData(url: string, dados?: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const postRequest: VoidFunction = async () => {
					try {
						const resp: any = await this.http.post(url, dados || { }, await this.getRequestOptions(url));
						console.log("---->>> postData() de ", url);
						console.log("---->>> dados do post acima", dados);
						console.log("---->>> response do post acima ", resp);
						this.header = null;
						if (!resp || resp.status < 200 || resp.status > 299) throw new Error('Falha ao enviar os dados ao serviço remoto.');
						resolve(this.formatResponse(resp));
					} catch (error) {
						this.catchError(error, url, postRequest, reject, dados);
					}
				};
				postRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async deleteData(url: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const deleteRequest: VoidFunction = async () => {
					try {
						const resp: any = await this.http.delete(url, { }, await this.getRequestOptions(url));
						this.header = null;
						if (!resp || resp.status < 200 || resp.status > 299) throw new Error('Falha ao excluir os dados ao serviço remoto.');
						resolve(this.formatResponse(resp));
					} catch (error) {
						this.catchError(error, url, deleteRequest, reject);
					}
				};
				deleteRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async downloadFile(url: string, destFilename: string): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const downloadRequest: VoidFunction = async () => {
					try {
						const result: any = await this.http.download(url, destFilename, await this.getRequestOptions(url));
						this.header = null;
						if (!result) throw new Error('Falha ao baixar o arquivo do serviço remoto.');

						if (this.platform.is("capacitor")) {
							resolve(result);
						} else {
							const dataType = result.type;
							const binaryData = [result];
							const downloadLink = document.createElement('a');
							downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
							if (destFilename) {
								downloadLink.setAttribute('download', destFilename);
							}
							downloadLink.click();
							resolve(result);
						}
					} catch (error) {
						this.catchError(error, url, downloadRequest, reject);
					}
				};
				downloadRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async uploadNativeFile(url: string, filePath: string, fileKey: string, data?: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const uploadRequest: VoidFunction = async () => {
					try {
						const result: any = await this.httpNative.uploadFile(url, data || { }, await this.getRequestOptions(url), filePath, fileKey);
						console.log(result);
						this.header = null;
						resolve(result);
					} catch (error) {
						console.error(error);
						this.catchError(error, url, uploadRequest, reject);
					}
				};
				uploadRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	async uploadFile(url: string, formData: FormData, data?: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			if (this.isConnected()) {
				const uploadRequest: VoidFunction = async () => {
					try {
						const headers: HttpHeaders = await this.getRequestOptions(url, true);
						const result: any = await this.httpClient.post(url, formData, { headers: headers, params: data || { }, responseType: this.getBrowserResponseType(headers) }).toPromise<any>();
						console.log(result);
						this.header = null;
						resolve(result);
					} catch (error) {
						this.catchError(error, url, uploadRequest, reject);
					}
				};
				uploadRequest();
			} else {
				reject(this.formatError({ status: 599}, url));
			}
		});
	}

	buildUrlQueryParams(params: any, urlRequest: string): string {
		if (!params) return urlRequest;

		let paramStr: string = '';
		for (const key in params) {
			if (params[key] || (typeof params[key] === 'number' && params[key] === 0)) {
				paramStr += paramStr.length === 0 ? '?' : '&';
				paramStr += (key + '=' + encodeURIComponent(params[key])).trim();
			}
		}
		return (urlRequest || '').replace('?', '') + paramStr;
	}

	buildUrlPathParams(params: any, urlRequest: string): string {
		if (!params) return urlRequest;

		for (const key in params) {
			if (params[key] != null) {
				urlRequest = urlRequest.replace("{" + key + "}", encodeURIComponent(params[key]));
			}
		}
		// console.log("urlRequest: " + urlRequest);
		return urlRequest;
	}

	/**
	 * Adiciona properties no header da requisição. Passar os campos chave-valor.
	 * Exemplo: key = 'Content-Type'; value = 'application/json'
	 */
	addParamsHeader(key: string, value: any): void {
		if (!this.header) {
			this.header = { };
		}
		if (this.header[key]) {
			delete this.header[key];
		}
		this.header[key] = value;
	}

	/**
	 * Retorna o cabeçalho com os parametros setados para a requisição.
	 */
	async getRequestOptions(urlRequest: string, forceWebHeaders?: boolean): Promise<any> {
		if (!this.isLogin(urlRequest)) {
			await this.addTokenToHeader();
		} else {
			if (this.header) {
				if (this.header["Consumerid"]) delete this.header["Consumerid"];
				if (this.header["withCredentials"]) delete this.header["withCredentials"];
			}
		}
		if (this.header) {
			return !this.platform.is('capacitor') || forceWebHeaders ? new HttpHeaders(this.header) : this.header;
		}
		return null;
	}

	/* Private métodos
	================================*/
	private getBrowserResponseType(headers: HttpHeaders): any {
		try {
			const responseType: string = headers.get('responseType');
			return responseType && responseType.indexOf("text") >= 0 ? 'text' : 'json';
		} catch (error) {
			return "json";
		}
	}

	private async configNativeClient(headers: any): Promise<void> {
		if (this.platform.is('capacitor')) {
			let headersStr: string = '';
			try {
				headersStr = JSON.stringify(headers || { }).toLowerCase();
			} catch (e) { }
			if (headersStr.indexOf('text/plain') >= 0) {
				this.httpNative.setDataSerializer('utf8');
			} else if (headersStr.indexOf('application/x-www-form-urlencoded') >= 0) {
				this.httpNative.setDataSerializer('urlencoded');
			} else if (headersStr.indexOf('multipart/form-data') >= 0) {
				this.httpNative.setDataSerializer('multipart');
			} else {
				// default
				this.httpNative.setDataSerializer('json');
			}
			this.httpNative.setRequestTimeout(20);
			await this.httpNative.setServerTrustMode('nocheck');
		}
	}

	private browserSerializeFormUrlEncoded(headers: HttpHeaders, body?: any): any {
		if (!body || !headers) return body;
		try {
			const contentType: string = headers.get('Content-Type');
			if (contentType && contentType.indexOf("application/x-www-form-urlencoded") >= 0) {
				const params: URLSearchParams = new URLSearchParams();
				for (const key in body) {
					if (body[key] != null) {
						params.append(key, body[key]);
					}
				}
				return params.toString();
			}
		} catch (e) {
			console.error(e);
		}
		return body;
	}

	private formatResponse(response: any): any {
		let body: any = null;
		try {
			if (this.platform.is('capacitor')) {
				if (response.data != null) {
					if (typeof response.data === 'string' && (response.data.trim().startsWith('{') || response.data.trim().startsWith('['))) {
						body = JSON.parse(response.data);
					} else {
						let cleanResp: string = response.data.replace(/\\"/gi, '\'').trim();
						if (cleanResp.startsWith('"') && cleanResp.length > 0) cleanResp = cleanResp.substr(1);
						if (cleanResp.endsWith('"') && cleanResp.length > 0) cleanResp = cleanResp.substr(0, cleanResp.length - 1);
						body = cleanResp;
					}
				} else {
					body = typeof response === 'string' ? JSON.parse(response) : response;
				}
			} else {
				body = response;
			}
		} catch (e) {
			//sem content no response
			console.log("Erro no formatResponse, ou sem content no response", response);
		}
		return body;
	}

	private async catchError(error: any, url: string, retryCallback: any, reject: any, dados?: any): Promise<void> {
		try {
			const e: ErroApp = await this.formatError(error, url, dados);
			if (e.tryAgain) {
				retryCallback();
			} else {
				this.header = null;
				reject(e);
			}
		} catch (error) {
			reject(error);
		}
	}

	private async formatError(error: any, url?: string, dados?: any): Promise<any> {
		console.log('Falha ao acessar o path [' + (url || 'Não especificado') + '].');
		console.log('Body: ' + JSON.stringify(dados || { }));
		console.log('Erro inicial: ' + JSON.stringify(error || { msg: 'Não especificado'}) + '.');
		// const erroObj: { status: number, name: string, message: string } = { } as any;

		const erroObj: ErroApp = {
			status: 0,
			message: "Ocorreu uma falha na operação, verifique sua internet ou tente novamente mais tarde.",
			tryAgain: false
		};
		if (error && error.status) {
			switch (error.status) {
				case 403:
					erroObj.message = 'Acesso negado.';
					break;
				case 404:
					erroObj.message = 'Nenhum resultado encontrado.';
					break;
				case 500:
					erroObj.message = 'Ocorreu um erro na comunicação com o Servidor. Tente novamente mais tarde.';
					break;
				case 501:
				case 502:
				case 503:
				case 504:
					erroObj.message = 'O serviço remoto parece estar indisponível no momento. Tente novamente mais tarde.';
					break;
				case 599:
					erroObj.message = 'Não conectado! Conecte-se à Internet e tente novamente.';
					break;
				default:
					const msgOriginal: any = (error.error || error.message || error.mensagem || error.msg || error.error || error.erro || 'Erro desconhecido.');
					if (typeof msgOriginal == 'string') {
						erroObj.message = msgOriginal.replace(/[_\"]+/g, '').trim();
					}
					break;
			}
			erroObj.status = error.status;
			erroObj.name = 'Erro ' + (error.status || 'desconhecido');
		} else if (error && (typeof error === 'string' || error instanceof Error || error.message)) {
			erroObj.message = typeof error === 'string' ? error : error.message || 'Falha ao executar a requisição ao serviço remoto.';
		}

		const body: string = typeof error.data === 'string' ? error.data : JSON.stringify(error.data || '{}');
		// Mensagem tratada do servidor no body ({status: <status>, mensagem: <mensagem>})
		if (body && body.length > 0 && !erroObj.message) {
			if (body.trim().startsWith('{') || body.trim().startsWith('[')) {
				erroObj.message = 'Ocorreu um erro na comunicação com o Servidor. Tente novamente mais tarde.';
			} else {
				erroObj.message = body;
			}
		}
		return erroObj;
	}

	private async addTokenToHeader(): Promise<void> {
		if (this.token && this.token.trim().length > 0) {
			let tokenDecoded: any = jwt_decode(this.token);
			if (tokenDecoded && tokenDecoded.exp) {
				let now = new Date().getTime();
				if ((tokenDecoded.exp * 1000) < now) {
					try {
						await this.usuarioService.refreshToken();
					} catch (e) {
						throw e;
					}
				}
				this.addParamsHeader('Authorization', (this.token.indexOf('Bearer') >= 0 ? this.token : 'Bearer ' + this.token).trim());
				this.addParamsHeader('withCredentials', 'true');
			}
		}
		this.addParamsHeader('Consumerid', environment.serviceConfig.params.consumer_id);
	}

	isConnected(): boolean {
		if (this.platform.is('capacitor')) return this.connected && this.network.type !== 'none';
		return true;
	}

	private isLogin(urlRequest: string): boolean {
		return urlRequest.indexOf(AUTH_END_POINT) !== -1;
	}
}
